import React from "react"
import SectionTitle from "./SectionTitle"
import { useTranslation } from "react-i18next"

export default function StaffingProcess() {
  const { t } = useTranslation()
  const title = t("CAREER.STAFFING_PROCESS.TITLE")
  const description = t("CAREER.STAFFING_PROCESS.DESCRIPTION")

  return (
    <section className="container text-center">
      <div className="flex flex-col items-center justify-center gap-8 md:flex-row">
        <div className="flex flex-col">
          <SectionTitle title={title} />
          <p>{description}</p>
        </div>

        <div className="grow">
          <img src="assets/teams/staffing_process.png" alt="staffing-process" />
        </div>
      </div>
    </section>
  )
}
