import React from "react"
import { ICultureItem } from "../../libs/interfaces"

export default function CultureItemMobile({
  title,
  description,
  image,
}: ICultureItem) {
  return (
    <section className="flex flex-row items-center gap-1 rounded-xl bg-primary-100">
      <img src={image} alt={title} width={128} height={128} />
      <div className="flex  flex-col ">
        <div className="text-md font-bold uppercase leading-normal text-primary-600 dark:text-primary-600">
          {title}
        </div>

        <div className="pl-3 pr-3 text-sm font-light text-slate-500 dark:text-slate-300 text-justify">
          {description}
        </div>
      </div>
    </section>
  )
}
