export function setupSlider(cssClassName: string) {
  // export function slider(cssClassName: string, config: TinySliderSettings) {
  const defaultConfig = {
    items: 1,
    slideBy: "page",
    autoplay: false,
    gutter: 12,
    autoplayButtonOutput: false,
    responsive: {
      700: {
        items: 2,
        gutter: 24,
      },
      900: {
        items: 3,
        gutter: 32,
      },
    },
    arrowKeys: true,
    controlsText: ["↢", "↣"],
    navPosition: "bottom",
    mouseDrag: true,
  }
  const config = {
    container: cssClassName,
    ...defaultConfig,
  }
  // @ts-ignore
  if (typeof tns !== "undefined") {
    // @ts-ignore
    tns(config)
  } else {
    setTimeout(() => {
      // @ts-ignore
      tns(config)
    }, 500)
  }
}
