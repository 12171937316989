import React from "react"
import { ICultureItem } from "../../libs/interfaces"

export default function CultureItem({
  title,
  description,
  image,
}: ICultureItem) {
  return (
    <section className="flex flex-row items-center gap-2 rounded-xl bg-primary-100 p-3 text-center md:flex-col md:items-start md:gap-6 md:bg-white md:p-0 dark:bg-slate-700">
      <div className="w-full rounded-xl md:w-full md:bg-primary-100 md:shadow-2xl">
        <img src={image} alt={title} />
      </div>

      <section className="flex flex-col gap-2 text-center">
        <div className="text-xl font-semibold uppercase leading-normal text-primary-600 dark:text-primary-600">
          {title}
        </div>
        <div className="pl-3 pr-3 text-sm font-light text-slate-500 dark:text-slate-300 text-justify">
          {description}
        </div>
      </section>
    </section>
  )
}
