import { GatsbyImage, getImage } from "gatsby-plugin-image"
import React from "react"
import { IEmployee } from "../../libs/interfaces"

function TeamEmployeeCard({ employee }: { employee: IEmployee }) {
  const image = getImage(employee.image)
  return (
    <>
      <div className="flex h-full flex-col items-stretch md:col-span-6 lg:col-span-3">
        <div className="team flex h-full flex-col items-center rounded-md border-gray-200 bg-white p-6 shadow-md dark:border-gray-700 dark:bg-slate-900 dark:shadow-gray-700">
          {image && (
            <div className="employeeAvatarContainer">
              <div className="employeeAvatarBg"></div>
              <GatsbyImage
                image={image}
                className="employeeAvatar h-24 w-24  shadow-md dark:shadow-gray-700"
                alt=""
              />
            </div>
          )}

          <div className="content mt-4 text-center">
            <span className="block text-lg font-medium ">
              {employee.first_name} {employee.last_name}
            </span>
            <span className="block text-slate-500 dark:text-slate-300">
              {employee.position}
            </span>

            <p className="mt-4 max-h-24 overflow-y-auto text-sm text-slate-500 dark:text-slate-300">
              {employee.description}
            </p>

            <ul className="mt-4 list-none">
              {employee.email && (
                <li className="inline">
                  <a
                    aria-label="Employee Email"
                    href={`mailto:${employee.email}`}
                    className={`btn btn-icon btn-sm rounded-md border hover:border-primary-600 hover:bg-primary-600 hover:text-white dark:border-gray-800`}
                  >
                    <i className="mdi mdi-email"></i>
                  </a>
                </li>
              )}
              {employee.linkedIn && (
                <li className="inline">
                  <a
                    aria-label="Employee Linkedin"
                    href={employee.linkedIn}
                    className={`btn btn-icon btn-sm rounded-md border hover:border-primary-600 hover:bg-primary-600 hover:text-white dark:border-gray-800`}
                  >
                    <i className="mdi mdi-linkedin"></i>
                  </a>
                </li>
              )}
            </ul>
          </div>
        </div>
      </div>
    </>
  )
}

export default TeamEmployeeCard
