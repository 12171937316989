import React from "react"
import SectionTitle from "./SectionTitle"
import { useTranslation } from "react-i18next"
import ProfitItem from "./ProfitItem"
import { IProfitItem } from "../../libs/interfaces"

export default function ProfitAndAdvantage() {
  const { t } = useTranslation()
  const title = t("CAREER.BENEFIT.TITLE")
  const subtitle = t("CAREER.BENEFIT.SUBTITLE")

  const items: IProfitItem[] = [
    "flexibility",
    "remote",
    "retrouvailles",
    "evolution",
    "sante",
  ].map((v) => {
    return {
      title: t(`CAREER.BENEFIT.${v.toUpperCase()}.TITLE`),
      description: t(`CAREER.BENEFIT.${v.toUpperCase()}.DESCRIPTION`),
      image: `/assets/teams/benefit/${v}.png`,
    }
  })

  return (
    <section className="container bg-primary-100 pb-4 text-center font-Montserrat font-bold md:bg-white dark:bg-slate-700">
      <SectionTitle title={title} />
      <div className="font-Montserrat font-medium text-slate-500 dark:text-slate-300">
        {subtitle}
      </div>

      <section className="mt-6 flex flex-row flex-wrap items-center justify-center gap-4 md:gap-8">
        {items.map((c: IProfitItem) => {
          return (
            <div className="w-full md:w-1/4">
              <ProfitItem
                title={c.title}
                description={c.description}
                image={c.image}
              />
            </div>
          )
        })}
      </section>
    </section>
  )
}
