import React from "react"
import SectionTitle from "./SectionTitle"
import { useTranslation } from "react-i18next"
import CultureItem from "./CultureItem"
import CultureItemMobile from "./CultureItemMobile"
import { ICultureItem } from "../../libs/interfaces"

export default function OurValue() {
  const { t } = useTranslation()
  const title = t("CAREER.CULTURE_TITLE")
  const subtitle = t("CAREER.CULTURE_SUBTITLE")

  let isMobile = false

  if (typeof window !== "undefined") {
    isMobile = window.screen.width <= 768
  }

  const cultures: ICultureItem[] = [
    {
      title: t("CAREER.CULTURE.PERSEVERANCE_TITLE"),
      description: t("CAREER.CULTURE.PERSEVERANCE_DESCRIPTION"),
      image: "/assets/teams/culture/plan.png",
    },
    {
      title: t("CAREER.CULTURE.OPTIMISM_TITLE"),
      description: t("CAREER.CULTURE.OPTIMISM_DESCRIPTION"),
      image: "/assets/teams/culture/optimism.png",
    },
    {
      title: t("CAREER.CULTURE.TEAMWORK_TITLE"),
      description: t("CAREER.CULTURE.TEAMWORK_DESCRIPTION"),
      image: "/assets/teams/culture/team.png",
    },
    {
      title: t("CAREER.CULTURE.ENGAGEMENT_TITLE"),
      description: t("CAREER.CULTURE.ENGAGEMENT_DESCRIPTION"),
      image: "/assets/teams/culture/engagement.png",
    },
  ]

  return (
    <section className="container text-center font-Montserrat font-bold">
      <SectionTitle title={title} />
      <div className="font-Montserrat font-medium text-slate-500 dark:text-slate-300">
        {subtitle}
      </div>

      <section className="mt-6 grid grid-cols-1 gap-8 md:grid-cols-2 xl:grid-cols-4">
        {cultures.map((c: ICultureItem) => {
          if (isMobile) {
            return (
              <CultureItemMobile
                title={c.title}
                description={c.description}
                image={c.image}
              />
            )
          } else {
            return (
              <CultureItem
                title={c.title}
                description={c.description}
                image={c.image}
              />
            )
          }
        })}
      </section>
    </section>
  )
}
