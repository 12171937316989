import React from "react"
import { graphql } from "gatsby"

import Seo from "../components/Seo"
import TeamDescription from "../components/Team/TeamDescription"
import TeamHeader from "../components/Team/TeamHeader"
import TeamEmployeesContainer from "../components/Team/TeamEmployeesContainer"
import Layout from "../components/Layout"
import { IEmployee, IJobOffer } from "../libs/interfaces"
import OurValue from "../components/Team/OurValue"
import ProfitAndAdvantage from "../components/Team/ProfitAndAdvantage"
import StaffingProcess from "../components/Team/StaffingProcess"
import JobsOffer from "../components/Team/JobsOffer"

const TeamPage = ({ data }: any) => {
  const {
    title,
    subtitle,
    desc_title,
    desc_text,
    desc_image,
    employees_text,
    employees_title,
    header_img,
    find_job_action,
  } = data.static.frontmatter

  const seo = {
    metaTitle: title,
    metaDescription: subtitle,
  }

  const employees: IEmployee[] = [
    ...data.employees.nodes.map((node: any) => ({
      id: node.id,
      image: data.employeesImg.nodes.find((el: any) => {
        return node.frontmatter.avatar == el.name
      })?.childrenImageSharp[0],
      ...node.frontmatter,
      order: node.frontmatter.order ? node.frontmatter.order : 999,
    })),
  ].sort((a, b) => a.order - b.order)

  const offers: IJobOffer[] = [
    ...data.offers.nodes.map((node: any) => ({
      id: node.id,
      html: node.html,
      ...node.frontmatter,
    })),
  ]

  const desc_img_data = data.staticImg.nodes.find((el: any) => {
    return el.name == desc_image
  })?.childrenImageSharp[0]

  const header_img_data = data.staticImg.nodes.find((el: any) => {
    return el.name == header_img
  })?.childrenImageSharp[0]
  let with_slider = false
  if (typeof window !== "undefined") {
    with_slider = window.screen.width <= 768
  }
  return (
    <Layout>
      <Seo seo={seo} lang={data.locales.edges[0]?.node.language} />
      <TeamHeader
        title={title}
        subtitle={subtitle}
        header_img={header_img_data}
        find_job_action={find_job_action}
      />
      <TeamDescription
        title={desc_title}
        text={desc_text}
        desc_image={desc_img_data}
      />

      <section className="mt-8">
        <OurValue />
      </section>

      <section className="mt-8 md:mt-24">
        <ProfitAndAdvantage />
      </section>

      <section className="mt-8 md:mt-24">
        <StaffingProcess />
      </section>

      <section className="mt-8 md:mt-24">
        <TeamEmployeesContainer
          title={employees_title}
          text={employees_text}
          employees={employees}
          withSlider={with_slider}
        />
      </section>

      <section className="mt-8 md:mt-24" id="jobs">
        <JobsOffer jobOffers={offers} />
      </section>
    </Layout>
  )
}

export default TeamPage

export const query = graphql`
    query ($language: String!) {
        locales: allLocale(filter: { language: { eq: $language } }) {
            edges {
                node {
                    ns
                    data
                    language
                }
            }
        }
        employees: allMarkdownRemark(
            filter: {
                frontmatter: {
                    slug: { glob: "team/employees/*" }
                    lang: { eq: $language }
                }
            }
        ) {
            nodes {
                id
                html
                frontmatter {
                    lang
                    slug
                    first_name
                    last_name
                    position
                    avatar
                    linkedIn
                    email
                    description
                    order
                }
            }
        }
        employeesImg: allFile(
            filter: {
                extension: { regex: "/(jpg)|(png)|(tif)|(tiff)|(webp)|(jpeg)/" }
                relativePath: { glob: "assets/teams/employees/*" }
            }
        ) {
            nodes {
                name
                childrenImageSharp {
                    gatsbyImageData
                }
            }
        }
        static: markdownRemark(
            frontmatter: { slug: { eq: "team/static" }, lang: { eq: $language } }
        ) {
            id
            frontmatter {
                lang
                slug
                title
                subtitle
                desc_title
                desc_text
                desc_image
                employees_title
                employees_text
                header_img
            }
        }
        staticImg: allFile(
            filter: {
                extension: { regex: "/(jpg)|(png)|(tif)|(tiff)|(webp)|(jpeg)/" }
                relativePath: { glob: "assets/teams/*" }
            }
        ) {
            nodes {
                name
                childrenImageSharp {
                    gatsbyImageData
                }
            }
        }
        offers: allMarkdownRemark(
            filter: {
                frontmatter: {
                    slug: { glob: "jobs/offers/*" }
                    lang: { eq: $language }
                }
            }
        ) {
            nodes {
                html
                id
                frontmatter {
                    slug
                    company
                    name
                    location
                    type
                    date
                    salary_period
                    salary
                    logo_light
                    logo_dark
                }
            }
        }
    }
`
