import { GatsbyImage, getImage, IGatsbyImageData } from "gatsby-plugin-image"
import React from "react"

interface ITeamDescription {
  title: string
  text: string
  desc_image: IGatsbyImageData
}

function TeamDescription({ title, text, desc_image }: ITeamDescription) {
  const image = getImage(desc_image)

  return (
    <section className="relative mt-8">
      <div className="container">
        <div className="grid grid-cols-1 items-center gap-[30px] sm:grid-cols-2 md:grid-cols-12">

          <div className="md:col-span-6 lg:col-span-5 hidden md:block">
            <div className="grid grid-cols-1 items-center gap-6">
              <div className="col-span-6">
                {image && (
                  <div className="teamDescImgContainer">
                    <div className="teamDescImgBg -z-1"></div>
                    <GatsbyImage
                      image={image}
                      className="h-full w-full"
                      alt=""
                    />
                  </div>
                )}
              </div>
            </div>
          </div>

          <div className="mt-8 md:col-span-6 md:mt-0 lg:col-span-7">
            <div className="lg:ml-5">
              <h3 className="mb-6 text-2xl font-medium leading-normal md:text-3xl md:leading-normal">
                {title}
              </h3>

              <p
                className="our_mission_text max-w-xl text-slate-500 dark:text-slate-300 text-justify"
                dangerouslySetInnerHTML={{ __html: text }}
              ></p>
            </div>
          </div>
          
          <div className="md:col-span-6 lg:col-span-5 md:hidden -mt-6">
            <div className="grid grid-cols-1 items-center gap-6">
              <div className="col-span-6">
                {image && (
                  <div className="teamDescImgContainer">
                    <div className="teamDescImgBg -z-1"></div>
                    <GatsbyImage
                      image={image}
                      className="h-full w-full"
                      alt=""
                    />
                  </div>
                )}
              </div>
            </div>
          </div>

        </div>
      </div>
    </section>
  )
}

export default TeamDescription
