import React from "react"
import { IJobOffer } from "../../libs/interfaces"
import JobsContainer from "../Jobs/JobsContainer"
import { Trans, useTranslation } from "react-i18next"
import SectionTitle from "./SectionTitle"

export default function JobsOffer({ jobOffers }: { jobOffers: IJobOffer[] }) {
  const { t } = useTranslation()
  const title = t("CAREER.JOBS.TITLE")
  const subtitle = t("CAREER.JOBS.SUBTITLE")
  const spontaneousTitle = t("CAREER.JOBS.SPONTANEOUS_CANDIDATURE_TITLE")
  const spontaneousDesc = t("CAREER.JOBS.SPONTANEOUS_CANDIDATURE_DESC")

  return (
    <section className="text-center md:container md:mb-24">
      {/*<div className="bg-gray-900 text-gray-200 dark:bg-gray-200 dark:text-gray-900 md:bg-white md:text-gray-900">
        <SectionTitle title={title} />
        <div className="">{subtitle}</div>
      </div>
      <JobsContainer jobOffers={jobOffers} />*/}

      <div className="rounded-md bg-primary-200 p-6 md:rounded-2xl md:p-12 dark:bg-slate-700">
        <div className="text-3xl font-bold md:text-4xl">{spontaneousTitle}</div>
        <p className="mt-8">{spontaneousDesc}</p>

        <div className="mt-8 text-center">
          <a
            href="mailto:s-mmarguery@digikare.com"
            className="btn btn-primary rounded-md text-sm"
            aria-label="find_job_action"
          >
            <Trans>CAREER.CANDIDATER</Trans>
          </a>
        </div>
      </div>
    </section>
  )
}
