import React, { useEffect } from "react"
import { IEmployee } from "../../libs/interfaces"
import TeamEmployeeCard from "./TeamEmployeeCard"
import { setupSlider } from "../../services/setupSlider"

function TeamEmployeesContainer({
                                  title,
                                  text,
                                  employees,
                                  withSlider,
                                }: {
  employees: IEmployee[]
  title: string
  text: string
  withSlider?: boolean
}) {
  if (withSlider) {
    useEffect(() => {
      setupSlider(".slider-tns")
    })
  }

  return (
    <section className="relative">
      <div className="container">
        <div className="grid grid-cols-1 pb-8 text-center">
          <h3
            className="mb-6 text-2xl font-medium leading-normal md:text-3xl md:leading-normal">
            {title}
          </h3>
          <p
            className="mx-auto max-w-xl text-slate-500 dark:text-slate-300"
            dangerouslySetInnerHTML={{ __html: text }}
          ></p>
        </div>
        {withSlider && (
          <div className="slider-tns mt-8">
            {employees.map((employee: IEmployee) => {
              return (
                <div>
                  <TeamEmployeeCard key={employee.id} employee={employee} />
                </div>
              )
            })}
          </div>
        )}
        {!withSlider && (
          <div className="mt-8 grid grid-cols-1 items-center gap-[30px] sm:grid-cols-2 md:grid-cols-12 lg:grid-cols-9">
            {employees.map((employee: IEmployee) => {
              return <TeamEmployeeCard key={employee.id} employee={employee} />
            })}
          </div>
        )}
      </div>
    </section>
  )
}

export default TeamEmployeesContainer
